import React from "react";
import Logo from "../assets/images/logo.webp";
import GoogleRating from "../assets/images/google-rating.webp";
import TrustPilot from "../assets/images/trust-pilot.webp";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigation = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <a href="https://api.whatsapp.com/send?phone=+918428421222&text=Hi! I want to learn Digital Marketing from 'Social Eagle'.
How are you?" class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>

        {/* <i class="fa-brands fa-whatsapp"></i> */}
      </a>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-center align-items-center py-5">
              <div className="col-lg-9 col-md-7 col-12 ">
                <div className="">
                  <h1 className="footer-heading">
                    Become a Digital Entrepreneur,
                  </h1>
                  <h1 className="footer-heading-gradient">Take Action Now!</h1>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-8 mt-lg-0 mt-5 ">
                <div className="flexColumn">
                  <a
                    href="https://optin.socialeagle.in/#/apply"
                    target="_blank"
                  >
                    <button type="button" class="btn btn-book-expert-call w-100">
                      Book Expert Call
                    </button>
                  </a>
                  <Link to="/Course" onClick={scrollToTop}>
                    <button
                      // onClick={() => navigation("Course")}
                      type="button"
                      class="btn btn-explore-course w-100"
                    >
                      Explore Course
                    </button>
                  </Link>

                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row rating-img-mob align-items-center py-2">
              <div className="col-lg-6 col-md-5 col-10 mb-4" >
                <img width="284" height="64" loading="lazy" className="footer-logo" src={Logo} alt=""></img>
              </div>
              <div className="col-lg-3 col-8 col-md-3">
                <div className="p-3">
                  <a href="https://g.co/kgs/3quSx3Z" target="_blank">
                    <img loading="lazy"
                      className="google-rating-img"
                      src={GoogleRating}
                      alt=""
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-8 col-md-3">
                <div className="p-3">
                  <a href="https://www.trustpilot.com/review/socialeagle.in" target="_blank">
                    <img loading="lazy"
                      className="google-rating-img"
                      src={TrustPilot}
                      alt=""
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row justify-content-between align-items-center py-4">
              <div className="col-lg-3 col-md-6">
                <ul className="list-unstyled d-flex justify-content-start rating-img-mob gap-4 pl-0 mb-0">
                  <li>
                    <a
                      href="https://www.facebook.com/SocialEagleOfficial/"
                      target="_blank"
                      className="social-media-links mb-0"
                    >
                      <i class="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/socialeagleofficial/?hl=en"
                      target="_blank"
                      className="social-media-links mb-0"
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/socialeagle/"
                      target="_blank"
                      className="social-media-links mb-0"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@socialeagleofficial1155"
                      target="_blank"
                      className="social-media-links mb-0"
                    >
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12 col-12 my-lg-0 my-3">
                <ul className="list-unstyled footerRowContent  pl-0 mb-0">
                  <li>
                    <a href="#/PrivacyPolicy" onClick={scrollToTop} className="footer-text-link mb-0">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#/TermsandConditions"
                      className="footer-text-link mb-0"
                      onClick={scrollToTop}
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="#/RefundandCancellationPolicy"
                      className="footer-text-link mb-0"
                      onClick={scrollToTop}
                    >
                      Refund & Cancellation Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 ">
                <p className="footer-text-copyright mb-0">Copyright @ 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
